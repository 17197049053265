/**
 * 提现组件
 */
import { t } from '@lingui/macro'
import { Select, Message, Button, Modal } from '@nbit/arco'
import { useState, useEffect } from 'react'
import TradeInputNumber from '@/features/trade/trade-input-number'
import Icon from '@/components/icon'
import { useRequest, useUpdateEffect } from 'ahooks'
import LazyImage, { Type } from '@/components/lazy-image'
import { oss_svg_image_domain_address } from '@/constants/oss'
import {
  getPayWithdraw,
  getFiatPrice,
  getPayPreWithdraw,
  PostFiatPaywithdraw,
  getFiatPriceRate,
  getFiatPayWithdraw,
} from '@/apis/assets/fiat'
import { link } from '@/helper/link'
import { CoinListTypeEnum } from '@/constants/assets'
import { CoinList } from '@/features/assets/main/deposit/coin-list'
import Transfer from '@/features/c2c/center/transfer'
import { getPaymentManagementRoutePath, getKycManagementRoutePath } from '@/helper/route'
import { UserSendValidateCodeBusinessTypeEnum } from '@/constants/user'
import { useUserStore } from '@/store/user'
import { CertificationLevel } from '@/features/kyc/kyt-const'
import { formatCurrency } from '@/helper/decimal'
import { faitAmount, verifyUserWithdraw } from '@/helper/assets'
import UserSafetyVerification from '@/features/user/withdraw-safety-verification'
import { PaymentManagement, WithdrawAccountStatusEnum } from '@/constants/assets/fiat'
import OpenSecurityPopup from '@/features/assets/common/open-security-popup'
import { getIsSingleFaitMode } from '@/helper/assets/fiat'
import { useAssetsStore } from '@/store/assets'
import { useCommonStore } from '@/store/common'
import { decimalUtils } from '@nbit/utils'
import { CurrencyModeErationEnum } from '@/constants/common'
import { Payment } from '@/typings/api/assets/fiat-payment'
import { paymentType, accountType, AllCurrencyListResp } from '@/typings/api/assets/fiat'
import AccountPop from '../account-pop'
import { cashWithdrawalDisplayMethod } from './fiat-withdraw-tools'
import styles from './index.module.css'

interface depositLayoutProps {
  /** 收款方式 */
  paymentTypeFilterLists: paymentType[]
  /** 确认提现 - 收款方式 */
  payments: paymentType[]
  /** 最小提现金额 */
  min: string
  /** 最大提现金额 */
  max: string
  /** 初始法币 */
  firstFiatCode: string
  /** 可用法币 */
  currencyFiat: string
  /** 是否用的字典默认值 */
  isDefault: boolean
  /** 关闭弹窗回调 */
  onclick: () => void
  /** 收款方式切换回调 */
  changeLegalCurrencyId: () => void
  /** 收款充值货币回调 */
  changeCharge: (val) => void
  /** 切换收款账号回调 */
  changeAccount: (i, item) => void
  /** 获取虚拟币单位 */
  cryptCode: string
  /** 多币种信息 */
  withdrawalCoinList?: AllCurrencyListResp[]
  /** 多币种动态法币信息 */
  defaultValueCurrency?: any
  areaCurrencyData?: any
}

enum CodeType {
  // 账户余额不足
  balance = 'USER_BALANCE__NOT_ENOUGH',
  // 交易超过 KYC 交易上限
  tradeOver = 'TRADE_AMOUNT_OVER_KYC_LIMIT',
  // 需要 KYC 认证
  kYCRequired = 'NEED_KYC_AUTHORIZED',
  // 提现数量超过限制
  withdrawalExceedLimit = 'WITHDRAW_OVER_LIMIT',
  // 修改密码再 24 小时之内不能提现
  resetPwdLimit = 'RESET_PWD_LIMIT',
  // 修改密码再 48 小时之内不能提现
  resetSafeLimit = 'RESET_SAFE_LIMIT',
  // 通过
  pass = 'PASS',
}

interface Rule {
  key: string
  code: CertificationLevel | CodeType
  confirmText: string
  render: () => string
  showCancle: boolean
  onConfirm: () => void
}

export function FiatWithdrawLayout({
  changeLegalCurrencyId,
  onclick,
  paymentTypeFilterLists,
  payments,
  min,
  max,
  isDefault,
  currencyFiat,
  firstFiatCode,
  changeCharge,
  changeAccount,
  cryptCode,
  withdrawalCoinList,
  defaultValueCurrency,
  areaCurrencyData,
}: depositLayoutProps) {
  const SafeCalcUtil = decimalUtils.SafeCalcUtil
  const { merchantInfo } = useAssetsStore()
  const { localeInfo } = useCommonStore()
  const Option = Select.Option
  const offset = 2
  const [visibleCoinList, setVisibleCoinList] = useState(false) // 币种选择列表展示状态
  const [currentValue, seCurrentValue] = useState(firstFiatCode) // 充值金额单位
  const [amount, setAmount] = useState('') // 充值金额
  const [visibleTransfer, setVisibleTransfer] = useState<boolean>(false)
  const [fiatCode, setFiatCode] = useState(firstFiatCode)
  const [showModal, setShowModal] = useState(false)
  const [verifyModal, setVerifyModal] = useState(false)
  const [applyFor, setApplyFor] = useState(false)
  const [showUserSafetyVerification, setShowUserSafetyVerification] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [receiptId, setReceiptId] = useState(paymentTypeFilterLists[0]?.id)
  const [defaultPaymentTypeFilterLists, setDefaultPaymentTypeFilterLists] = useState('')
  const [defaultReceiptId, setDefaultReceiptId] = useState('')
  const [matchedRule, setMatchedRule] = useState<Rule>()
  // 是否显示开启两项验证提示
  const [isShowOpenSecurity, setIsShowOpenSecurity] = useState(false)
  const [accountInfo, setAccountInfo] = useState<accountType[]>()
  const [defaultAccount, setDefaultAccount] = useState<string | undefined>(undefined)
  const [changeLable, setChangeLable] = useState('')
  const isSingleFaitMode = getIsSingleFaitMode() ? merchantInfo?.symbol : cryptCode
  const [visiblePop, setvisiblePop] = useState({
    visible: false as boolean,
    val: {} as paymentType,
    outerIndex: 0 as number,
    oldVal: {} as Payment,
  })

  /** 收付款管理 */
  const goLink = () => {
    link(getPaymentManagementRoutePath())
    onclick()
  }

  const getAccount = val => {
    const paymentsAccount = payments?.filter(item => item?.value === val)[0]?.accountInfo || []
    setAccountInfo(paymentsAccount)

    const account =
      paymentsAccount?.filter(item => item?.enabled === WithdrawAccountStatusEnum?.available)?.[0]?.id || undefined
    setDefaultAccount(account)
  }

  // 获取法币对 USDT 的汇率
  const { data: dataRes, run: getPrice } = useRequest(
    async () => {
      return localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
        ? await getFiatPriceRate({ fiatCode: defaultValueCurrency?.fiatCode, areaCurrency: currentValue })
        : await getFiatPrice({ fiatCode })
    },
    { manual: true }
  )
  const activityAmount = dataRes?.data?.activityAmount || 0

  // 监听选中法币的变化
  useEffect(() => {
    localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency && getPrice()
  }, [fiatCode, dataRes?.data?.balance, defaultValueCurrency?.fiatCode])

  useEffect(() => {
    localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency && defaultValueCurrency?.fiatCode && getPrice()
  }, [defaultValueCurrency?.fiatCode])

  useEffect(() => {
    if (!isDefault) {
      setDefaultPaymentTypeFilterLists(paymentTypeFilterLists[0]?.value || '')
      setChangeLable(paymentTypeFilterLists[0]?.value || '')
      getAccount(paymentTypeFilterLists[0]?.value)
      setDefaultReceiptId(paymentTypeFilterLists[0]?.id || '')
      return
    }
    setDefaultPaymentTypeFilterLists(payments?.[0]?.value || '')
  }, [paymentTypeFilterLists, payments])

  /** 选择主币 */
  const onChangeCoin = coinInfo => {
    changeCharge(coinInfo.fiatCode)
  }

  useEffect(() => {
    seCurrentValue(currencyFiat)
    setFiatCode(currencyFiat)
    changeLegalCurrencyId()
  }, [currencyFiat])

  const hasFaceToFacePayment = data => {
    return (
      data.value === PaymentManagement.FaceToFace &&
      paymentTypeFilterLists.some(method => {
        return method.value === PaymentManagement.FaceToFace
      }) &&
      !isDefault
    )
  }

  const handleBoxClick = (index, data) => {
    if (data.accountInfo.length > 0 || hasFaceToFacePayment(payments[index])) {
      setActiveIndex(index)
      setChangeLable(payments?.[index]?.label || '')
      setReceiptId(payments[index].id)
    }
  }

  const AuthenticationType = {
    UNVERIFIED: t`user.personal_center_03`,
    STANDARD: t`features_user_personal_center_menu_navigation_index_5101265`,
  }

  const {
    userInfo: { kycType, rsPwdLimitInd, kycCountryCd },
  } = useUserStore()

  const ruleList = [
    {
      key: 'notSufficientFunds',
      code: CodeType.balance,
      showCancle: true,
      confirmText: t`features/assets/main/index-4`,
      onConfirm: () => setVisibleTransfer(true),
      render: () => t`features_assets_main_withdraw_fiat_withdraw_index_1vxtvcqexh`,
    },
    {
      key: 'passwordChangeTimeLimit',
      code: CodeType.resetPwdLimit,
      showCancle: false,
      confirmText: t`features_trade_spot_index_2510`,
      onConfirm: () => {},
      render: () => t`features_assets_main_withdraw_fiat_withdraw_index_ihqpoudprk`,
    },
    {
      key: 'passwordTimeLimit',
      code: CodeType.resetSafeLimit,
      showCancle: false,
      confirmText: t`features_trade_spot_index_2510`,
      onConfirm: () => {},
      render: () => t`features_assets_main_withdraw_fiat_withdraw_index_cdekafzdl7`,
    },
    {
      key: 'kyc',
      code: CertificationLevel.notCertified,
      showCancle: true,
      confirmText: t`features_user_personal_center_menu_navigation_index_5101265`,
      onConfirm: () => {
        setShowModal(false)
        onclick()
        link(getKycManagementRoutePath())
      },
      render: () => {
        return t({
          id: 'features_assets_main_withdraw_fiat_withdraw_index_ob5tap3og8',
          values: { 0: `${AuthenticationType.UNVERIFIED}` },
        })
      },
    },
    {
      key: 'kycStandard',
      code: CertificationLevel.personalStandardCertification,
      showCancle: true,
      confirmText: t`features_user_personal_center_menu_navigation_index_5101266`,
      onConfirm: () => {
        setShowModal(false)
        onclick()
        link(getKycManagementRoutePath())
      },
      render: () => {
        return t({
          id: 'features_assets_main_withdraw_fiat_withdraw_index_afi_wtlldl',
          values: { 0: `${AuthenticationType.STANDARD}` },
        })
      },
    },
    {
      key: 'personalAdvancedCertification',
      code: CertificationLevel.personalAdvancedCertification,
      showCancle: false,
      confirmText: t`features_trade_spot_index_2510`,
      onConfirm: () => {},
      render: () => t`features_assets_main_withdraw_fiat_withdraw_index_svfsnma11j`,
    },
    {
      key: 'enterpriseCertification',
      code: CertificationLevel.enterpriseCertification,
      showCancle: false,
      confirmText: t`features_trade_spot_index_2510`,
      onConfirm: () => {},
      render: () => t`features_assets_main_withdraw_fiat_withdraw_index_ryblflt8ht`,
    },
  ]

  /** 根据法币的切换获取金额方法 */
  const selectCurrencyAmount = (isThousandths = true) => {
    return faitAmount(
      dataRes?.data?.balance,
      Number(dataRes?.data?.rate),
      true,
      getIsSingleFaitMode() && currentValue === merchantInfo?.symbol ? merchantInfo?.coinPrecision || 2 : offset,
      isThousandths
    )
  }

  // 根据匹配的规则显示相应的弹窗
  const showModalContent = matchedRule ? (
    <Modal
      title={t`features_assets_main_withdraw_fiat_withdraw_index_vdaypw4hcw`}
      visible={showModal}
      className={styles['trade-modal']}
      onOk={() => setShowModal(false)}
      footer={null}
      onCancel={() => setShowModal(false)}
      autoFocus={false}
      focusLock
    >
      {matchedRule?.render()}
      {[
        'passwordChangeTimeLimit',
        'kycLimit',
        'personalAdvancedCertification',
        'enterpriseCertification',
        'passwordTimeLimit',
      ].includes(matchedRule?.key) ? (
        <Button type="primary" className={'know-button'} onClick={() => setShowModal(false)}>
          {matchedRule?.confirmText}
        </Button>
      ) : (
        <div>
          {matchedRule?.showCancle && (
            <Button className={'cancel mr-4'} onClick={() => setShowModal(false)}>{t`trade.c2c.cancel`}</Button>
          )}
          <Button type="primary" className={'cancel'} onClick={matchedRule?.onConfirm}>
            {matchedRule?.confirmText}
          </Button>
        </div>
      )}
    </Modal>
  ) : null

  const availabCurrentAmount =
    currentValue !== merchantInfo?.symbol ? selectCurrencyAmount(false) : dataRes?.data?.balance

  const handleWithdraw = async () => {
    const payment = paymentTypeFilterLists?.find(item => item?.value === defaultPaymentTypeFilterLists)
    if (Number.isNaN(Number(amount)) || !amount) {
      Message.error(t`features_assets_main_withdraw_fiat_withdraw_index_15ibteerpr`)
      return
    }
    const availableAmount = faitAmount(dataRes?.data?.balance, Number(dataRes?.data?.rate), true, offset, false)

    if (
      (localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency &&
        payment?.max &&
        payment?.min &&
        Number(amount) > Number(payment?.max)) ||
      Number(amount) < Number(payment?.min)
    ) {
      return Message.error(
        `${payment?.label || ''} ${t`features_assets_main_withdraw_fiat_withdraw_index_oad4rez4y4`} ${
          payment?.min || 0
        }-${payment?.max || 0} ${
          localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
            ? defaultValueCurrency?.fiatCode || ''
            : currentValue
        }`
      )
    }

    if (!getIsSingleFaitMode() && Number(amount) > Number(availableAmount)) {
      Message.error(
        t({
          id: 'features_assets_main_withdraw_fiat_withdraw_index_15i9lyajoqe',
          values: {
            0: availableAmount,
            1:
              localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
                ? defaultValueCurrency?.fiatCode || ''
                : currentValue,
          },
        })
      )
      return
    }

    if (
      (localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency &&
        payment?.max &&
        payment?.min &&
        Number(amount) > Number(payment?.max)) ||
      Number(amount) < Number(payment?.min)
    ) {
      return Message.error(
        `${payment?.label || ''}${t`features_assets_main_withdraw_fiat_withdraw_index_oad4rez4y4`}${
          payment?.min || 0
        }-${payment?.max || 0}${
          localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
            ? defaultValueCurrency?.fiatCode || ''
            : currentValue
        }`
      )
    }

    if (getIsSingleFaitMode() && Number(amount) > Number(availabCurrentAmount)) {
      return Message.error(
        `${t`features_assets_main_withdraw_fiat_withdraw_index_uu6qpbsjtl`} ${formatCurrency(
          availabCurrentAmount,
          offset
        )} ${currentValue}`
      )
    }

    if (Number(amount) < Number(min)) {
      Message.error(
        t({
          id: 'features_assets_main_withdraw_fiat_withdraw_index_9zvazlfzr3',
          values: { 0: min, 1: currentValue },
        })
      )
      return
    }
    if (max && Number(amount) > Number(max)) {
      Message.error(
        t({
          id: 'features_assets_main_withdraw_fiat_withdraw_index_fo9loafoqe',
          values: { 0: max, 1: currentValue },
        })
      )
      return
    }

    if (!defaultAccount) {
      return Message.error(t`features_assets_main_withdraw_fiat_withdraw_index_w_krtktrmv`)
    }

    const res =
      localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
        ? await getFiatPayWithdraw({
            number: Number(amount),
            fiatCode: defaultValueCurrency?.fiatCode,
            areaCurrency: currentValue,
            receiptId: defaultAccount,
          })
        : await getPayPreWithdraw({ number: Number(amount), fiatCode: currentValue })
    const data = res.data
    if (data === CodeType.tradeOver) {
      const matchedRule = ruleList.find(rule => rule.code === kycType)
      setMatchedRule(matchedRule)
      setShowModal(true)
    } else if (data === CodeType.pass) {
      const index = payments.findIndex(item => item.value === defaultPaymentTypeFilterLists)
      setActiveIndex(index)
      setVerifyModal(true)
    } else {
      const matchedRule = ruleList.find(rule => rule.code === data)
      setMatchedRule(matchedRule)
      setShowModal(true)
    }
  }

  const getTagText = (text, color = 'brand') => (
    <div className={styles['tag-text']}>
      <div className={`y-line w-0.5 h-2.5`} style={{ backgroundColor: color }}></div>
      <div className="text">{text}</div>
    </div>
  )
  /**
   *
   *支付方式选择
   */
  const paySelectChange = val => {
    getAccount(val)
    const payment = paymentTypeFilterLists?.find(item => item.value === val)
    setReceiptId(payment?.id)
    setDefaultPaymentTypeFilterLists(val)
  }

  /** 选择收款账号 */
  const payAccountChange = val => {
    setDefaultAccount(val)
    /** 注释收款账号和收款方式相互联动的问题 */
    // const paymentId = accountInfo?.find(item => item?.id === val)
    // setReceiptId(val)
    // changeAccount(val, defaultPaymentTypeFilterLists)
  }

  // 显示账户信息
  const getUserAccount = item => {
    return (
      item?.email ||
      item?.enterpriseID ||
      item?.mobile ||
      item?.randomCode ||
      item?.taxIdNumber ||
      item?.account ||
      item?.name
    )
  }

  /** 提币前校验 */
  const onVerifyUserWithdraw = async () => {
    // 校验提币权限，是否开启两项安全验证、是否 24 小时内修改登录密码、是否 48 小时内修改安全项、是否有风控问题
    const { isSuccess, isOpenSafeVerify } = await verifyUserWithdraw()

    // 验证失败 - 是否开启两项验证
    if (!isSuccess && !isOpenSafeVerify) {
      setIsShowOpenSecurity(true)
      return
    }
    setShowUserSafetyVerification(true)
    return isSuccess
  }

  const confirmedWithdrawal = () => {
    if ((payments[activeIndex] && payments[activeIndex]?.id) || hasFaceToFacePayment(payments[activeIndex])) {
      if (changeLable === defaultPaymentTypeFilterLists) {
        const paymentsId = payments[activeIndex].accountInfo?.find(val => val.id === defaultAccount)
        setReceiptId(paymentsId?.id)
      } else {
        setReceiptId(payments[activeIndex]?.id)
      }
      onVerifyUserWithdraw()
      setChangeLable('')
    } else {
      Message.error(t`features_assets_main_withdraw_fiat_withdraw_index_oq4ixhc8d4`)
    }
  }

  const handleOnSuccess = async isTrue => {
    if (isTrue) {
      const data = {
        receiptId:
          (activeIndex === visiblePop?.oldVal?.newIndex && visiblePop?.oldVal?.id) || receiptId || defaultReceiptId,
        amount: Number(amount),
        fiatCode: currentValue,
      }
      const res =
        localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
          ? await PostFiatPaywithdraw({
              receiptId: data?.receiptId,
              amount: Number(amount),
              fiatCode: defaultValueCurrency?.fiatCode,
              areaCurrency: currentValue,
            })
          : await getPayWithdraw(data)
      if (res.data) {
        setApplyFor(true)
        setAmount('')
        setvisiblePop({ val: {} as paymentType, oldVal: {} as Payment, visible: false, outerIndex: 0 })
        setVerifyModal(false)
      }
    }
  }

  /** 获取选中账户 */
  const getItem = () => {
    let val = ''
    accountInfo?.forEach(res => {
      if (res?.id === defaultAccount) {
        val = getUserAccount(res)
      }
    })
    return visiblePop?.oldVal?.id ? getUserAccount(visiblePop?.oldVal) : val
  }

  /** 关闭弹窗方法 */
  const onvisibleClose = () => {
    setvisiblePop({ ...visiblePop, val: {} as paymentType, visible: false, outerIndex: 0 })
  }

  /** 收款方式选择事件 */
  const onAccountInfo = e => {
    setActiveIndex(visiblePop.outerIndex)
    setChangeLable(payments?.[visiblePop.outerIndex]?.label || '')
    setvisiblePop({ ...visiblePop, oldVal: e, visible: false })
  }

  /** 获取值 */

  useEffect(() => {
    firstFiatCode && seCurrentValue(firstFiatCode)
  }, [firstFiatCode])

  return (
    <div className={styles.scoped}>
      <div className="brl-header">
        <p className="brl-title">
          {currentValue} {t`features_user_components_sidebar_index_etltjevzl9`}
        </p>
        <div className="brl-header-icon">
          <Icon name="close" fontSize={20} onClick={onclick} className="close-icon" />
        </div>
      </div>

      <div className="money flex justify-between">
        <span>{t`features_assets_main_withdraw_fiat_withdraw_index_mcs1bc3tna`}</span>
      </div>

      <TradeInputNumber
        precision={
          getIsSingleFaitMode() && currentValue === merchantInfo?.symbol ? merchantInfo?.coinPrecision : offset
        }
        min={Number(min)}
        max={Number(max)}
        placeholder={t({
          id: 'features_assets_main_deposit_fiat_deposit_index_8hg3pqloyh',
          values: { 0: formatCurrency(min, offset) },
        })}
        onChange={val => {
          setAmount(val.toString())
        }}
        value={amount}
        suffix={
          <div className="flex items-center">
            <span
              onClick={() => {
                setVisibleCoinList(true)
              }}
            >
              <span className="current-value">
                {localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
                  ? defaultValueCurrency?.fiatCode || ''
                  : currentValue}
              </span>
              <Icon name="icon_agent_center_drop" fontSize={8} className="center-drop ml-2" />
            </span>
            <div className="all">
              <span className="shu">|</span>
              <span
                className="text cursor-pointer"
                onClick={() => {
                  const newBalance = faitAmount(
                    dataRes?.data?.balance,
                    Number(dataRes?.data?.rate),
                    true,
                    offset,
                    false
                  )

                  const SingleFaitAmount =
                    currentValue !== merchantInfo?.symbol
                      ? Number(selectCurrencyAmount(false))
                      : getIsSingleFaitMode()
                      ? Number(dataRes?.data?.balance)
                      : Number(SafeCalcUtil.sub(newBalance, activityAmount || 0))
                  SingleFaitAmount > Number(max) ? setAmount(max.toString()) : setAmount(SingleFaitAmount.toString())
                }}
              >{t`common.all`}</span>
            </div>
          </div>
        }
      />
      <p>
        {
          <span className="money">
            {t`features_assets_futures_common_adjust_margin_modal_adjust_margin_form_5101489`}
            {t`features_assets_main_withdraw_fiat_withdraw_index_itckuzbtec`}
            {/* {currentValue !== merchantInfo?.symbol &&
              `${selectCurrencyAmount()} ${defaultValueCurrency?.fiatCode || currentValue} ≈ `}
            {`${formatCurrency(
              dataRes?.data?.balance || 0,
              getIsSingleFaitMode() ? merchantInfo?.coinPrecision || 2 : offset
            )} ${dataRes?.data?.cryptCode || ''}`} */}

            {cashWithdrawalDisplayMethod(
              currentValue,
              dataRes?.data,
              offset,
              defaultValueCurrency,
              selectCurrencyAmount,
              areaCurrencyData
            )}
          </span>
        }
        {localeInfo?.currencyMode !== CurrencyModeErationEnum.multiCurrency &&
        activityAmount &&
        Number(activityAmount) > 0 ? (
          <span>
            {t`features_c2c_center_coll_pay_manage_index_loafhg8k4aubv8fnnpyqu`}
            {t`features_assets_main_withdraw_withdraw_form_index_iwn8mcvd2f`} {activityAmount}
            {dataRes?.data?.cryptCode}
            {t`features_c2c_center_coll_pay_manage_index_kwjbatlrha5htpcfybg7j`}
          </span>
        ) : (
          <span></span>
        )}
      </p>

      {/* 跳转娱乐区代码--nb 没有娱乐区所以注释，nc 需要打开 */}

      {/* <p className="money mt-1">
        {t`features_assets_main_withdraw_fiat_withdraw_index__e8uhsgtiw`}
        <span
          className="bet cursor-pointer"
          onClick={() => {
            link(getRecreationPageRoutePath())
            onclick()
          }}
        >{t`features_assets_main_withdraw_fiat_withdraw_index_glg9o1afls`}</span>
      </p> */}

      <p className="money balance">{t`features_assets_main_withdraw_fiat_withdraw_index_olthkr_ylb`}</p>
      <Select
        placeholder=""
        style={{ width: 536 }}
        className={'brl-select'}
        arrowIcon={<Icon name="icon_agent_center_drop" className="center-drop w-2 h-2" />}
        onChange={e => paySelectChange(e)}
        value={defaultPaymentTypeFilterLists}
      >
        {payments.map((item, i) => (
          <Option key={i} value={item?.value || ''}>
            <div className="flex h-10">{getTagText(item.label, item.color)}</div>
          </Option>
        ))}
      </Select>

      <p className="money balance">
        {t`features_assets_main_withdraw_fiat_withdraw_index_sk2qtsuht6`}
        <span
          className="add-account"
          onClick={() => {
            link(getPaymentManagementRoutePath())
            onclick()
          }}
        >{t`features_c2c_advertise_post_advertise_index_ysrjqenh7kh2ucsite6p7`}</span>
      </p>
      <Select
        placeholder={
          accountInfo?.length === 0
            ? t`features_assets_main_withdraw_fiat_withdraw_index_c52l037jre`
            : t`features_c2c_advertise_post_advertise_index_eve39t4b5fkjstx_gsqix`
        }
        style={{ width: 536 }}
        className={'brl-select mb-8'}
        arrowIcon={<Icon name="icon_agent_center_drop" className="center-drop w-2 h-2" />}
        onChange={e => payAccountChange(e)}
        value={defaultAccount}
        disabled={accountInfo?.length === 0}
      >
        {accountInfo &&
          accountInfo.map((item, i) => (
            <Option key={i} value={item.id} disabled={item?.enabled === WithdrawAccountStatusEnum.unavailable}>
              <div className="flex h-10">{getUserAccount(item)}</div>
            </Option>
          ))}
      </Select>

      <Button type="primary" className={'deposit'} onClick={handleWithdraw}>
        {t`features_user_components_sidebar_index_etltjevzl9`}
      </Button>

      <div className="button-title">
        <div>
          {/* <div className="orange"></div> */}
          {/* 底部文案，接口后端说先不弄，所以先注释掉 */}
        </div>
      </div>

      {showModal && showModalContent}

      {verifyModal && (
        <Modal
          title={t`features_assets_main_withdraw_fiat_withdraw_index_hdk4z24eya`}
          visible={verifyModal}
          className={styles['confirmed-withdrawal']}
          onCancel={() => {
            setVerifyModal(false)
            setReceiptId('')
          }}
          footer={null}
          autoFocus={false}
          focusLock
        >
          <p className="total">
            {t`features/assets/main/my-assets/index-4`} <span className="num">{amount}</span>
            {defaultValueCurrency?.fiatCode || currentValue}
          </p>
          {currentValue !== isSingleFaitMode && (
            <p className="predict">
              {t`features_assets_main_withdraw_fiat_withdraw_index_s3gxxo6fsg`}
              <span className="currency">
                {` ${faitAmount(amount, Number(dataRes?.data?.rate), false, offset, true)} ${
                  isSingleFaitMode || currentValue
                }`}
              </span>
            </p>
          )}
          <div className="payment-method flex justify-between">
            <span className="payment">{t`features_assets_main_withdraw_fiat_withdraw_index_olthkr_ylb`}</span>
            <span className="manage" onClick={goLink}>
              {t`features_c2c_trade_free_trade_free_placeorder_modal_index_al4i170-w89xouup7p2jm`}
            </span>
          </div>
          <div className="all-payments-box">
            {payments.map((data, index) => (
              <div
                key={index}
                className={`payment-manage-box flex justify-between ${
                  index === activeIndex ? 'payment-manage-active' : ''
                }`}
                onClick={() => handleBoxClick(index, data)}
              >
                <div>
                  <span className="line" style={{ backgroundColor: data.color }}></span>
                  <span>{data.label}</span>
                </div>
                <div>
                  {(data?.accountInfo && data?.accountInfo.length > 0) || hasFaceToFacePayment(data) ? (
                    <div className={'flex'}>
                      <span className="account align-middle;">
                        {data.label === defaultPaymentTypeFilterLists
                          ? getItem()
                          : getUserAccount(
                              visiblePop.oldVal?.id
                                ? visiblePop.oldVal
                                : data?.accountInfo && data?.accountInfo.length > 0
                                ? data?.accountInfo[0]
                                : {}
                            )}
                      </span>
                      {data?.qrCodeAddr && <Icon name={'icon_web_rebate_qr'} className="icon-web-rebate-qr" />}
                      {data?.IsShowRight && (
                        <Icon
                          onClick={e => {
                            e?.stopPropagation()
                            setvisiblePop({ ...visiblePop, visible: true, val: data, outerIndex: index })
                          }}
                          name={'icon_arrow_banner_right_v2'}
                          className="icon-arrow-right ml-2"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <span className="add-payment">{t`features_assets_main_withdraw_fiat_withdraw_index_plrscghews`}</span>
                      <span
                        className="add cursor-pointer"
                        onClick={goLink}
                      >{t`features_c2c_trade_free_trade_index_cror__n4ujg_bk0mdmtk0`}</span>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Button type="primary" className={'know-button'} onClick={confirmedWithdrawal}>
            {t`features_assets_main_withdraw_fiat_withdraw_index_hdk4z24eya`}
          </Button>

          {/* ** 选中账户 * */}
          {visiblePop.visible && (
            <AccountPop
              oldObj={visiblePop?.oldVal}
              defaultAccount={visiblePop?.val?.id || ''}
              payments={visiblePop.val}
              visiblePop={visiblePop.visible}
              onvisibleClose={() => onvisibleClose()}
              getUserAccount={getUserAccount}
              onAccountInfo={onAccountInfo}
            />
          )}
        </Modal>
      )}

      {applyFor && (
        <Modal
          title={t`features_assets_main_withdraw_fiat_withdraw_index_ycljmnfkgk`}
          visible={applyFor}
          className={styles['apply-withdrawal']}
          onCancel={() => setApplyFor(false)}
          footer={null}
          autoFocus={false}
          focusLock
        >
          <LazyImage
            className="success-icon"
            src={`${oss_svg_image_domain_address}success_icon`}
            imageType={Type.png}
          />
          <p className="verify">{t`features_assets_main_withdraw_fiat_withdraw_index_hkejbtwx1a`}</p>
          <p className="msg">
            {t`features_assets_main_withdraw_fiat_withdraw_index_fn4pkoly4x`} 1 ~ 10{' '}
            {t`features_assets_main_withdraw_fiat_withdraw_index_9ncybqab8v`}
          </p>
          <Button
            className={'apply-button mr-5'}
            onClick={() => {
              onclick()
              link('/')
            }}
          >{t`features_assets_main_withdraw_fiat_withdraw_index_b5vsqfc_qz`}</Button>
          <Button
            type="primary"
            className={'apply-button'}
            onClick={() => {
              setApplyFor(false)
              setVerifyModal(false)
              setShowUserSafetyVerification(false)
            }}
          >
            {t`features_assets_main_withdraw_fiat_withdraw_index_jjuddz0jfe`}
          </Button>
        </Modal>
      )}

      {/* 币种选择 */}
      {visibleCoinList && (
        <CoinList
          withdrawalCoinList={withdrawalCoinList}
          type={CoinListTypeEnum.deposit}
          onChangeCoin={val => {
            setAmount('')
            onChangeCoin(val)
          }}
          isShow={visibleCoinList}
          setShow={setVisibleCoinList}
          currentCoin={
            localeInfo?.currencyMode === CurrencyModeErationEnum.multiCurrency
              ? defaultValueCurrency?.fiatCode
              : currentValue
          }
        />
      )}

      {/* 划转 */}
      {visibleTransfer && (
        <Transfer
          visible={visibleTransfer}
          setVisible={setVisibleTransfer}
          changeReverse
          onSubmit={() => {
            setVisibleTransfer(false)
            setShowModal(false)
            getPrice()
          }}
        />
      )}

      {/* 安全验证 */}
      {showUserSafetyVerification && (
        <UserSafetyVerification
          isShow={showUserSafetyVerification}
          businessType={UserSendValidateCodeBusinessTypeEnum.fiatSendType}
          onClose={setShowUserSafetyVerification}
          onSuccess={handleOnSuccess}
        />
      )}

      {isShowOpenSecurity && (
        <OpenSecurityPopup
          isWithdraw
          isShow={isShowOpenSecurity}
          setIsShow={setIsShowOpenSecurity}
          setOtherClose={onclick}
        />
      )}
    </div>
  )
}
